<template>
  <div class="ts-contact m-0">
    <div class="ts-contact__content" v-if="$store.state.careerPage">
      <div class="ts-contact-form-container ts-shadow-1">
        <div v-if="!isFormSubmitted">
          <header class="ts-contact-form-container__header text-center">
            <h1
              class="ts-fs-4-ii ts-text-primary-1 fw-bold text-center mb-05 mb-lg-06"
            >
              {{ $store.state.careerPage.jobApplication.title }}
            </h1>
            <p
              class="ts-contact__content-description ts-fs-7 fw-light mb-06 mb-lg-07"
            >
              {{ $store.state.careerPage.jobApplication.description1 }} <br />
              {{ $store.state.careerPage.jobApplication.description2 }}
            </p>
          </header>

          <form @submit="postJobApplication" @submit.prevent="preventSubmit">
            <div class="row row-cols-2">
              <div class="mb-2 pe-1">
                <input
                  type="text"
                  class="form-control form-control-lg rounded-pill"
                  id="firstName"
                  :placeholder="
                    $store.state.careerPage.jobApplication.formPlaceHolder
                      .firstName
                  "
                  v-model="firstName"
                  minlength="2"
                  required
                />
              </div>
              <div class="mb-2 ps-1">
                <input
                  type="text"
                  class="form-control form-control-lg rounded-pill"
                  id="lastName"
                  :placeholder="
                    $store.state.careerPage.jobApplication.formPlaceHolder
                      .lastName
                  "
                  v-model="lastName"
                  maxlength="250"
                  required
                />
              </div>
            </div>
            <div class="mb-2">
              <input
                type="email"
                class="form-control form-control-lg rounded-pill"
                id="email"
                aria-describedby="emailHelp"
                :placeholder="
                  $store.state.careerPage.jobApplication.formPlaceHolder.email
                "
                v-model="email"
                maxlength="250"
                required
              />
            </div>
            <div
              class="ts-form-control-group d-flex mb-2"
              v-if="$store.state.countries"
            >
              <select
                class="form-select form-select-lg rounded-pill ts-phone country-codes"
                aria-label="Default select example"
                v-model="FormData.country_code"
              >
                <option
                  v-for="country in $store.state.countries"
                  :value="country.code"
                  :key="'mob-country-code-' + country.id"
                  :data-text="country.country_name + '(' + country.code + ')'"
                >
                  {{ country.country_name + "(" + country.code + ")" }}
                </option>
              </select>
              <div class="flex-fill">
                <input
                  type="tel"
                  class="form-control form-control-lg rounded-pill"
                  id="tsPhone"
                  aria-describedby="PhoneHelp"
                  placeholder="Phone"
                  v-model="FormData.number"
                  required
                />
              </div>
            </div>

            <div class="mb-2">
              <select
                class="form-select form-select-lg rounded-pill"
                aria-label="Default select example"
                v-model="currentLocation"
              >
                <option disabled>
                  {{
                    $store.state.careerPage.jobApplication.formPlaceHolder
                      .currentLocation
                  }}
                </option>
                <option
                  :value="location.state_name"
                  v-for="location in $store.state.locations"
                  :key="location.id"
                >
                  {{ location.state_name }}
                </option>
              </select>
            </div>
            <div class="mb-06 mb-lg-10">
              <div class="ts-attach-cv input-group mb-2" role="button">
                <label
                  class="input-group-text justify-content-center gap-3 align-items-center"
                  for="uploadCv"
                >
                  <Icon name="Complete" />
                  {{
                    cvLocation
                      ? cvLocation.name
                      : $store.state.careerPage.jobApplication.formPlaceHolder
                          .attachCv
                  }}
                </label>
              </div>
              <input
                @change="handleFileChange"
                type="file"
                name="files"
                class="form-control"
                id="uploadCv"
              />
            </div>
            <button
              type="submit"
              class="ts-btn ts-fs-7-ii ts-btn-secondary w-100 rounded-pill text-center"
            >
              {{ $store.state.careerPage.jobApplication.btnSubmit }}
            </button>
          </form>
        </div>

        <Congratulation v-else>
          <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-05">
            {{ $store.state.careerPage.Success.title }}
          </h1>
          <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-08 text-center">
            {{ $store.state.careerPage.Success.description }}
          </p>
          <button
            class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-secondary w-100 rounded-pill text-center"
            data-bs-dismiss="modal"
            type="button"
          >
            {{ $store.state.careerPage.Success.btnText }}
          </button>
        </Congratulation>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "../../assets/icons/index.vue";
import { axiosClient } from "../../axiosClient";
import { toast } from "vue3-toastify";
// import Congratulation from "../common/Congratulation.vue";
import Congratulation from "../Form/common/Congratulation.vue";
export default {
  name: "ApplicationForm",
  components: {
    Icon,
    Congratulation,
  },

  data() {
    return {
      FormData: {
        number: "",
        country_code: "+971",
      },
      firstName: null,
      lastName: null,
      email: null,
      currentLocation: "Current location",
      cvLocation: null,
      isFormSubmitted: false,
      postId: null,
    };
  },
  // Watch if ACtive language Change but not same as old Language
  watch: {
    jobactiveJobUpdate(newValue, oldValue) {
      if (oldValue) {
        if (newValue.id !== oldValue.id) {
          this.isFormSubmitted = false;
          // resest all values of contact form
          this.firstName = null;
          this.lastName = null;
          this.email = null;
          this.phoneNumber = null;
          this.currentLocation = "Current location";
          this.cvLocation = null;
          this.isFormSubmitted = false;
        }
      }
      this.updateCountryCode();
    },
    careerPageUpdate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentLocation =
          this.$store.state.careerPage.jobApplication.formPlaceHolder.currentLocation;
      }
    },
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getLocations");
        this.$store.dispatch("getCountries");
      }
    },
  },
  created() {
    this.$store.dispatch("getLocations");
    this.$store.dispatch("getCountries");
  },
  methods: {
    // postJobApplication() {
    //   const formData = new FormData();
    //   formData.append("files", this.cvLocation);
    //   // uploading files to strapi and getting id then post request to career
    //   axiosClient
    //     .post("api/upload", formData)
    //     .then((response) => {
    //       const imageId = response.data[0].id;
    //       axiosClient
    //         .post(`api/responses-careers`, {
    //           data: {
    //             jobId: this.$store.state.activeJob.id,
    //             jobTitle: this.$store.state.activeJob.jobTitle,
    //             firstName: this.firstName,
    //             lastName: this.lastName,
    //             email: this.email,
    //             phoneNumber: this.phoneNumber,
    //             currentLocation: this.currentLocation,
    //             cv: imageId,
    //           },
    //         })
    //         .then((response) => {
    //           console.log(response);
    //           this.isFormSubmitted = true;
    //         })
    //         .catch(function (error) {
    //           console.log(error);
    //           if (error.response.status === 429) {
    //             toast.error(
    //               this.$store.state.careerPage.errorMessages.spamMessagesError
    //             );
    //           }
    //         });
    //     })
    //     .catch((error) => {
    //       //handle error
    //       console.log(error);
    //     });
    // },

    postJobApplication() {
      const {
        activeJob: { id, jobTitle },
      } = this.$store.state;

      // First, submit the form without the image
      let _this = this;
      axiosClient
        .post(`api/responses-careers`, {
          data: {
            jobId: id,
            jobTitle: jobTitle,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phoneNumber: this.FormData.country_code + this.FormData.number,
            currentLocation: this.currentLocation,
            // Note: We're not including the cv field here since we're not uploading it yet
          },
        })
        .then((response) => {
          let careerPostId = response.data.data.id;
          this.postId = careerPostId;

          // If the form submission is successful, upload the image
          const formData = new FormData();
          formData.append("files", this.cvLocation);

          return axiosClient.post("api/upload", formData);
        })
        .then((response) => {
          const imageId = response.data[0].id;
          // After getting the image ID, initiate a PUT request to add the image ID to the form
          return axiosClient.put(`api/responses-careers/${this.postId}`, {
            data: {
              cv: imageId,
            },
          });
        })
        .then(() => {
          this.isFormSubmitted = true;
        })
        .catch((error) => {
          if (error.response && error.response.status === 429) {
            toast.error(_this.$store.state.errorMessges.spam_messages_error);
          } else {
            // Handle other errors or display a generic error message to the user
            if (_this.$store.state.errorMessges) {
              toast.error(_this.$store.state.errorMessges.try_again);
            }
          }
        });
    },

    handleFileChange(event) {
      let filePath = event.target.files[0];
      this.cvLocation = filePath;
    },

    updateCountryCode() {
      let app = this;
      [...document.querySelectorAll(".country-codes")].forEach(function (item) {
        app.$nextTick(function () {
          setTimeout(() => {
            if (item.selectedIndex > 0) {
              item.options[item.selectedIndex].text = item.value;
            }
          }, 600);
        });
        item.addEventListener("change", function handleChange(event) {
          event.target.options[event.target.selectedIndex].text =
            event.target.value;
        });
        item.addEventListener("mousedown", function handleChange(event) {
          [...event.target.options].forEach((option) => {
            option.text = option.dataset.text;
          });
          event.target.options[event.target.selectedIndex].text =
            event.target.value;
        });
      });
    },
  },

  computed: {
    careerPageUpdate() {
      return this.$store.getters.careerPageUpdate;
    },
    jobactiveJobUpdate() {
      return this.$store.getters.jobactiveJobUpdate;
    },
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  mounted() {
    this.updateCountryCode();
  },
};
</script>
<style scoped lang="scss">
.country-codes {
  width: 25%;
}
.ts-attach-cv {
  width: 100% !important;
  height: 150px !important;

  label {
    background-color: #ebebeb !important;
    cursor: pointer;
    border: 2px dashed #9b9b9b;
    border-radius: 28px;
  }

  label,
  input {
    width: 100% !important;
    height: 150px !important;
  }
}

#uploadCv {
  background-color: transparent !important;
  border: none;
  display: none;

  &::-webkit-file-upload-button {
    display: none;
  }
}
</style>
