<template>
  <section class="ts-brands-logos">
    <h1
      class="ts-fs-1 ts-text-gray-5 fw-bold text-center mb-06 mb-lg-10"
      v-if="$store.state.brandsSwiper"
    >
      {{ $store.state.brandsSwiper.Title }}
    </h1>
    <div class="ts-brands-logos__content position-relative overflow-hidden">
      <div class="loop-container mb-4" v-if="$store.state.brandsSwiper">
        <!-- :style="{ width: calculateWidth(brandItem) + 'px' }" -->
        <div
          v-for="brandItem in $store.state.brandsSwiper.Images.data"
          :key="brandItem.id"
        >
          <img
            width="80"
            :src="$store.state.baseUrl + brandItem.attributes.url"
            :alt="brandItem.attributes.alternativeText"
          />
        </div>
      </div>
      <div
        class="loop-container loop-container--reverse"
        v-if="$store.state.brandsSwiper"
      >
        <!-- :style="{ width: calculateWidth(brandItem) + 'px' }" -->
        <div
          v-for="brandItem in $store.state.brandsSwiper.Images.data"
          :key="brandItem.id"
        >
          <img
            width="80"
            :src="$store.state.baseUrl + brandItem.attributes.url"
            :alt="brandItem.attributes.alternativeText"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BrandsLogos",
  props: {
    msg: String,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  methods: {
    // calculateWidth(brandItem) {
    //   // Calculate the aspect ratio (width/height) of the image
    //   const aspectRatio =
    //     brandItem.attributes.width / brandItem.attributes.height;
    //   // Set the desired height
    //   const desiredHeight = 40; // You can adjust this value as needed
    //   // Calculate the width based on the aspect ratio and desired height
    //   const calculatedWidth = aspectRatio * desiredHeight;
    //   return Math.ceil(calculatedWidth); // Round up to the nearest integer
    // },
  },

  // Watch if Active language Change but not the same as the old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getBrands");
      }
    },
  },
  created() {
    this.$store.dispatch("getBrands");
  },
};
</script>

<style scoped lang="scss">
.loop-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: calc(2400px + 22rem);
  margin: 0 auto;
  gap: clamp(0.5rem, 6vw, 3rem);
}

.loop-container {
  div {
    // min-width: 200px;
    animation: slide 40s linear infinite;
    text-align: center;
  }
  &--reverse {
    div {
      animation: slide-reverse 40s linear infinite;
    }
  }
}

.ts-brands-logos {
  // img {
  //   height: auto;
  //   max-height: 60px;
  //   @media (max-width: 991px) {
  //     max-height: 50px;
  //   }
  // }

  &__content {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100px;
      height: 100%;
      z-index: 10;
      pointer-events: none;
    }

    &::before {
      left: 0;
      background: rgb(255, 255, 255);
      background: -moz-linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
    }

    &::after {
      right: 0;

      background: rgb(255, 255, 255);
      background: -moz-linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: -webkit-linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
    }
  }
}

@keyframes slide {
  0% {
    transform: translate3d(-1500px, 0, 0); // Start from the right
  }
  100% {
    transform: translate3d(0, 0, 0); // Move to the left
  }
}

@keyframes slide-reverse {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-1500px, 0, 0);
  }
}
</style>
