<template>
  <div>
    <header
      class="ts-contact-form-container__header ts-text-gray-4 text-center"
    >
      <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
        {{ $store.state.creatorSignupPage.Form1.title }}
      </h1>
      <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-05 mb-lg-07">
        {{ $store.state.creatorSignupPage.Form1.description }}
      </p>
    </header>
    <form @submit.prevent="submitFrom1">
      <div class="row row-cols-2 px-2">
        <div class="mb-2 px-1">
          <input
            type="text"
            class="form-control form-control-lg rounded-pill"
            id="firstName"
            :placeholder="
              $store.state.creatorSignupPage.Form1.placeholderFirstName
            "
            name="firstName"
            v-model="FormData.first_name"
            :class="{ 'is-invalid': FromErrorFlags.first_name }"
          />
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.first_name[0] }}
          </div> -->
        </div>
        <div class="mb-2 px-1">
          <input
            type="text"
            class="form-control form-control-lg rounded-pill"
            id="lastName"
            :placeholder="
              $store.state.creatorSignupPage.Form1.placeholderLastName
            "
            name="lastName"
            v-model="FormData.last_name"
            :class="{ 'is-invalid': FromErrorFlags.last_name }"
          />
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.last_name[0] }}
          </div> -->
        </div>
      </div>
      <div class="mb-2">
        <select
          class="form-select form-select-lg rounded-pill ts-phone"
          aria-label="Gender"
          v-model="FormData.gender"
          placeholder="Gender"
          :class="{ 'is-invalid': FromErrorFlags.gender }"
        >
          <option selected disabled value="">{{$store.state.creatorSignupPage.Form1.Gender[0].title}}</option>
          <option value="male">{{$store.state.creatorSignupPage.Form1.Gender[1].title}}</option>
          <option value="female">{{$store.state.creatorSignupPage.Form1.Gender[2].title}}</option>
          <option value="other">{{$store.state.creatorSignupPage.Form1.Gender[3].title}}</option>
        </select>
        <!-- <div class="invalid-feedback">{{ FromErrorMessages.gender[0] }}</div> -->
      </div>
      <div class="mb-08">
        <select
          class="form-select form-select-lg rounded-pill ts-phone"
          aria-label="Current Location"
          v-model="FormData.state_id"
          :class="{ 'is-invalid': FromErrorFlags.state_id }"
        >
          <option value="" selected disabled>Current Location</option>
          <option
            v-for="location in $store.state.locations"
            :value="location.id"
            :key="'locations-' + location.id"
          >
            {{ location.state_name }}
          </option>
        </select>
        <!-- <div class="invalid-feedback">{{ FromErrorMessages.state_id[0] }}</div> -->
      </div>
      <p class="ts-fs-7 px-3">Birthday</p>
      <div class="row row-cols-3 mb-2 px-2">
        <div class="px-1">
          <select
            class="form-select form-select-lg rounded-3 ts-phone"
            aria-label="Current Location"
            v-model="FormData.birth_year"
            :class="{ 'is-invalid': FromErrorFlags.birth_year }"
          >
            <option value="" selected disabled>Year</option>
            <option v-for="year in years" :value="year" :key="year">
              {{ year }}
            </option>
          </select>
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.birth_year[0] }}
          </div> -->
        </div>
        <div class="px-1">
          <select
            class="form-select form-select-lg rounded-3 ts-phone"
            v-model="FormData.birth_month"
            :class="{ 'is-invalid': FromErrorFlags.birth_month }"
            aria-label="Current Location"
            @change="change_month"
          >
            <option value="" selected disabled>Month</option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.birth_year[0] }}
          </div> -->
        </div>
        <div class="px-1">
          <select
            class="form-select form-select-lg rounded-3 ts-phone"
            aria-label="Current Location"
            v-model="FormData.birth_day"
            :class="{ 'is-invalid': FromErrorFlags.birth_day }"
          >
            <option value="" selected disabled>Day</option>
            <option v-for="day in days" :value="day" :key="'day-' + day">
              {{ day }}
            </option>
          </select>
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.birth_day[0] }}
          </div> -->
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import data from "@/StrapiIntegration/ServerCrenditials";
import { axiosAPI } from "../../../axiosClient";
import { toast } from "vue3-toastify";
export default {
  name: "CreatorForm1",

  data() {
    return {
      years: [],
      days: [],
      FormData: {
        first_name: "",
        last_name: "",
        birth_year: "",
        birth_month: "",
        birth_day: "",
        state_id: "",
        gender: "",
        birthday: "",
      },
      FromErrorFlags: {
        first_name: false,
        last_name: false,
        birth_year: false,
        birth_month: false,
        birth_day: false,
        state_id: false,
        gender: false,
      },
      FromErrorMessages: {
        first_name: [],
        last_name: [],
        birth_year: [],
        birth_month: [],
        birth_day: [],
        state_id: [],
        gender: [],
      },
    };
  },
  watch: {
    FormData: {
      handler() {
        this.FromErrorFlags = {
          first_name: false,
          last_name: false,
          birth_year: false,
          birth_month: false,
          birth_day: false,
          state_id: false,
          gender: false,
        };
        this.FromErrorMessages = {
          first_name: [],
          last_name: [],
          birth_year: [],
          birth_month: [],
          birth_day: [],
          state_id: [],
          gender: [],
        };
      },
      deep: true,
    },
  },
  methods: {
    change_month(event) {
      let Days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      let days = [];
      for (var i = 1; i <= Days[event.target.value - 1]; i++) {
        days.push(i);
      }
      this.days = days;
    },
    submitFrom1() {
      let errorFlag = false;
      if (this.FormData.first_name.trim() == "") {
        this.FromErrorFlags.first_name = true;
        this.FromErrorMessages.first_name.push("first_name_empty");
        errorFlag = true;
      } else {
        this.FromErrorFlags.first_name = false;
        this.FromErrorMessages.first_name.pop("first_name_empty");
      }
      if (this.FormData.last_name.trim() == "") {
        this.FromErrorFlags.last_name = true;
        this.FromErrorMessages.last_name.push("last_name_empty");
        errorFlag = true;
      } else {
        this.FromErrorFlags.last_name = false;
        this.FromErrorMessages.last_name.pop("last_name_empty");
      }
      if (this.FormData.birth_year == "") {
        this.FromErrorFlags.birth_year = true;
        this.FromErrorMessages.birth_year.push("birth_year_empty");
        errorFlag = true;
      } else {
        this.FromErrorFlags.birth_year = false;
        this.FromErrorMessages.birth_year.pop("birth_year_empty");
      }
      if (this.FormData.birth_month.trim() == "") {
        this.FromErrorFlags.birth_month = true;
        this.FromErrorMessages.birth_month.push("birth_month_empty");
        errorFlag = true;
      } else {
        this.FromErrorFlags.birth_month = false;
        this.FromErrorMessages.birth_month.pop("birth_month_empty");
      }
      if (this.FormData.birth_day == "") {
        this.FromErrorFlags.birth_day = true;
        this.FromErrorMessages.birth_day.push("birth_day_empty");
        errorFlag = true;
      } else {
        this.FromErrorFlags.birth_day = false;
        this.FromErrorMessages.birth_day.pop("birth_day_empty");
      }
      if (this.FormData.state_id == "") {
        this.FromErrorFlags.state_id = true;
        this.FromErrorMessages.state_id.push("state_id_empty");
        errorFlag = true;
      } else {
        this.FromErrorFlags.state_id = false;
        this.FromErrorMessages.state_id.pop("state_id_empty");
      }
      if (this.FormData.gender.trim() == "") {
        this.FromErrorFlags.gender = true;
        this.FromErrorMessages.gender.push("gender_empty");
        errorFlag = true;
      } else {
        this.FromErrorFlags.gender = false;
        this.FromErrorMessages.gender.pop("gender_empty");
      }
      if (errorFlag) {
        this.$emit("submitForm", {
          status: false,
        });
        return false;
      }
      this.FormData.birthday =
        this.FormData.birth_year +
        "-" +
        this.FormData.birth_month +
        "-" +
        this.FormData.birth_day;
      this.FormData["step"] = 1;
      if(localStorage.getItem('utm_source')) {
        this.FormData["source"] = localStorage.getItem('utm_source');
      } else {
        this.FormData["source"] = '';
      }
      if(localStorage.getItem('utm_medium')) {
        this.FormData["utm_medium"] = localStorage.getItem('utm_medium');
      } else {
        this.FormData["utm_medium"] = '';
      }
      if(localStorage.getItem('utm_campaign')) {
        this.FormData["utm_campaign"] = localStorage.getItem('utm_campaign');
      } else {
        this.FormData["utm_campaign"] = '';
      }
      let _this = this;
      axiosAPI
        .post(`v1/creator/web/signup`, this.FormData)
        .then((response) => {
          if (response.data && response.data.status) {
            this.$emit("submitForm", {
              status: true,
              creator_id: response.data.creator_id,
              data: data,
            });
          } else {
            this.$emit("submitForm", {
              status: false,
            });
          }
        })
        .catch(function (error) {
          _this.$emit("submitForm", {
            status: false,
          });
          if (error.response && error.response.data) {
            let error_msgs = [];
            for (const error_msg in error.response.data) {
              error_msgs.push(...error.response.data[error_msg]);
              // this.FromErrorMessages[error_msg] = error.response.data[error_msg];
              this.FromErrorFlags[error_msg] = false;
            }
            if (this.$store.state.errorMessges) {
              toast.info(this.$store.state.errorMessges[error_msgs[0]]);
            }
          }
        });
    },
  },
  beforeMount() {
    let d = new Date();
    for (let i = d.getFullYear() - 13; i >= 1930; i--) {
      // years start i
      this.years.push(i);
    }
    let Days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    for (let i = 1; i <= Days[0]; i++) {
      //add option days
      this.days.push(i);
    }
  },
};
</script>
