<template>
  <LoadingScreen v-if="!$store.state.blogDetails" />
  <div>
    <div class="ts-blog-page-wrapper">
      <BlogArticle />
      <div class="ts-container-fluid container-fluid">
        <RelatedNews>
          <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13">
            Related Posts
          </h1>
        </RelatedNews>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import RelatedNews from "../components/Blog/RelatedNews.vue";
import BlogArticle from "../components/Blog/BlogArticle.vue";

export default {
  name: "BlogPost",
  components: {
    LoadingScreen,
    BlogArticle,
    RelatedNews,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if Active language Change but not the same as the old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getBlogDetails", this.$route.params.id);
      }
    },
  },

  created() {
    this.$store.dispatch("getBlogDetails", this.$route.params.id);
  },

  beforeUnmount() {
    // Set blogDetails to null when leaving the page
    this.$store.commit("setBlogDetails", null);
  },
};
</script>

<style scoped lang="scss">
.ts-blog-page-wrapper {
  padding-top: clamp(160px, 15vw, 15rem);
  position: relative;
  isolation: isolate;

  @media (max-width: 767px) {
    padding-top: clamp(160px, 10vw, 15rem);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: clamp(300px, 75vw, 750px);
    background-color: #f5f5f5;
    z-index: -1;

    @media (max-width: 767px) {
      height: clamp(240px, 55vw, 350px);
    }
  }
}
</style>
