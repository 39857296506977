<template>
  <section class="ts-how-it-works-2" v-if="$store.state.brandPage">
    <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-03 mb-lg-07">
      {{ $store.state.brandPage.HowItWork.title }}
    </h1>
    <h1
      class="ts-fs-3-ii ts-text-gray-5 text-center fw-normal mb-07-ii mb-lg-13"
    >
      {{ $store.state.brandPage.HowItWork.subtitle.titleMain }}
      <span class="fw-bold">{{
        $store.state.brandPage.HowItWork.subtitle.titleHighlight
      }}</span>
    </h1>

    <div class="ts-how-it-works__content">
      <div class="ts-bg-radius ts-bg-radius--secondary z-minus-1"></div>
      <div
        class="ts-bg-radius ts-bg-radius--primary-secondary--mix z-minus-1"
      ></div>

      <div class="ts-how-it-works__cards">
        <div>
          <div class="ts-how-card ts-shadow-1 ts-text-gray-7 h-100">
            <img
              class="ts-how-card__img mb-02 mb-lg-08"
              :src="
                $store.state.baseUrl +
                '/uploads/list_check_svgrepo_com_25b111eb01.svg'
              "
              alt="alternativeText"
            />
            <h3 class="ts-fs-4 ts-text-gray-5 fw-bold mb-02 mb-lg-04">
              <span class="ts-text-primary-1">{{
                $store.state.brandPage.HowItWork.Cards[0].titleNo
              }}</span
              >{{ $store.state.brandPage.HowItWork.Cards[0].titleText }}
            </h3>
            <h5 class="ts-fs-5 fw-light mb-0">
              {{ $store.state.brandPage.HowItWork.Cards[0].description }}
            </h5>
          </div>
        </div>
        <div>
          <div class="ts-how-card ts-shadow-1 ts-text-gray-7 h-100">
            <img
              class="ts-how-card__img mb-02 mb-lg-08"
              :src="
                $store.state.baseUrl +
                '/uploads/rocket_launch_svgrepo_com_688a00834c.svg'
              "
              alt="alternativeText"
            />
            <h3 class="ts-fs-4 ts-text-gray-5 fw-bold mb-02 mb-lg-04">
              <span class="ts-text-primary-1">{{
                $store.state.brandPage.HowItWork.Cards[1].titleNo
              }}</span
              >{{ $store.state.brandPage.HowItWork.Cards[1].titleText }}
            </h3>
            <h5 class="ts-fs-5 fw-light mb-0">
              {{ $store.state.brandPage.HowItWork.Cards[1].description }}
            </h5>
          </div>
        </div>
        <div>
          <div class="ts-how-card ts-shadow-1 ts-text-gray-7 h-100">
            <img
              class="ts-how-card__img mb-02 mb-lg-08"
              :src="
                $store.state.baseUrl +
                '/uploads/report_svgrepo_com_af6f72f338.svg'
              "
              alt="alternativeText"
            />
            <h3 class="ts-fs-4 ts-text-gray-5 fw-bold mb-02 mb-lg-04">
              <span class="ts-text-primary-1">{{
                $store.state.brandPage.HowItWork.Cards[2].titleNo
              }}</span
              >{{ $store.state.brandPage.HowItWork.Cards[2].titleText }}
            </h3>
            <h5 class="ts-fs-5 fw-light mb-0">
              {{ $store.state.brandPage.HowItWork.Cards[2].description }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HowItWorks",
};
</script>

<style scoped lang="scss">
.ts-how-it-works {
  &__content {
    position: relative;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: clamp(1rem, 3vw, 2rem) !important;

    @media (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.ts-bg-radius--secondary {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 72% !important;
  height: 72% !important;
  padding-top: 65% !important;
  z-index: -2;
}

.ts-bg-radius--primary-secondary--mix {
  position: absolute;
  right: 0;
  top: 50%;
  width: 805px !important;
  height: 805px !important;
  padding-top: 805px !important;
  z-index: -1;
  transform: translate(-15%, -32%);
  opacity: 60%;
}

@media (max-width: 991px) {
  .ts-bg-radius--secondary {
    position: absolute;
    left: 40%;
    top: 25%;
    transform: translate(-50%, -50%);
    width: 180% !important;
    height: 150% !important;
    padding-top: auto !important;
    z-index: -2;
  }

  .ts-bg-radius--primary-secondary--mix {
    right: auto;
    left: 100%;
    transform: translate(-60%, -65%);
    z-index: -1;
  }
}
</style>
