<template>
  <section class="ts-hero d-flex align-items-center" :style="elementStyles">
    <div class="w-100" v-if="$store.state.brandPage">
      <div class="ts-container-fluid container-fluid mb-1 mb-lg-0">
        <div class="ts-hero__content text-center text-lg-start">
          <h1 class="ts-fs-1 ts-text-gray-5 fw-bold mb-06 mb-lg-07">
            {{ $store.state.brandPage.Banner.title }}
          </h1>
          <p class="ts-hero__desc ts-fs-5 fw-light mb-07-ii">
            {{ $store.state.brandPage.Banner.description }}
          </p>
          <div
            class="d-flex flex-column flex-sm-row ts-rtl-justify-content-end align-items-center gap-3"
          >
            <router-link
              class="ts-btn ts-btn-secondary rounded-pill px-3"
              :to="'/' + $store.state.brandPage.Banner.GetADemo.to"
            >
              {{ $store.state.brandPage.Banner.GetADemo.text }}
            </router-link>

            <router-link
              class="ts-btn ts-btn-primary rounded-pill px-3"
              :to="'/' + $store.state.brandPage.Banner.Signup.to"
            >
              {{ $store.state.brandPage.Banner.Signup.text }}</router-link
            >
          </div>
        </div>
      </div>
      <img
        v-if="$store.state.brandPage"
        class="w-100 d-lg-none"
        :src="
          $store.state.baseUrl +
          $store.state.brandPage.Banner.bannerImages.smallImage.data.attributes
            .url
        "
        alt="alternativeText"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "TsHero",

  computed: {
    elementStyles() {
      // Check if the brandPage exists in the store
      if (this.$store.state.brandPage) {
        // Define your base styles
        let styles = {
          "background-image": `url('${
            this.$store.state.baseUrl +
            this.$store.state.brandPage.Banner.bannerImages.mediumImage.data
              .attributes.url
          }')`,
        };

        // Add additional styles for the specified media query
        if (window.innerWidth >= 1920) {
          styles = {
            ...styles, // Keep the base styles
            "background-image": `url('${
              this.$store.state.baseUrl +
              this.$store.state.brandPage.Banner.bannerImages.largeImage.data
                .attributes.url
            }')`,
          };
        }

        return styles;
      } else {
        // Fallback styles if brandPage doesn't exist
        return {
          "background-image": "none", // or any other default background image
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ts-hero {
  background-color: #f9f9f9 !important;
  background-size: 100% auto;
  background-position: right bottom !important;
  // background-image: url("../../../public/images/Brand/brands__1440p.jpg");

  @media (max-width: 991px) {
    padding-bottom: 0;
    background-size: 130% auto !important;
  }
  @media (max-width: 575px) {
    background: #f9f9f9 !important;
    padding-bottom: 0 !important;
  }

  @media (min-width: 1920px) {
    // background-image: url("../../../public/images/Brand/ipad.png");
    background-size: 80% auto !important;
    background-position: 130% bottom !important;
  }
  @media (min-width: 2400px) {
    background-size: 1700px auto !important;
    background-position: 75% bottom !important;
  }

  &__content {
    max-width: 590px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
    @media (max-width: 420px) {
      max-width: 330px;
      margin: auto;
    }
  }

  &__desc {
    max-width: 540px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
}
</style>
