<template>
  <div>
    <div>
      <header
        class="ts-contact-form-container__header ts-text-gray-4 text-center"
      >
        <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
          {{ $store.state.brandSignupPage.Form1.title }}
        </h1>
        <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-05 mb-lg-07">
          {{ $store.state.brandSignupPage.Form1.description }}
        </p>
      </header>

      <form>
        <div class="row row-cols-2 px-2 mb-2">
          <div class="px-1">
            <input
              type="text"
              class="form-control form-control-lg rounded-pill"
              id="firstName"
              :placeholder="$store.state.brandSignupPage.Form1.firstName"
              v-model="FormData.first_name"
              :class="{ 'is-invalid': FromErrorFlags.first_name }"
            />
            <!-- <div class="invalid-feedback">
              {{ FromErrorMessages.first_name[0] }}
            </div> -->
          </div>
          <div class="px-1">
            <input
              type="text"
              class="form-control form-control-lg rounded-pill"
              id="lastName"
              :placeholder="$store.state.brandSignupPage.Form1.lastName"
              v-model="FormData.last_name"
              :class="{ 'is-invalid': FromErrorFlags.last_name }"
            />
            <!-- <div class="invalid-feedback">
              {{ FromErrorMessages.last_name[0] }}
            </div> -->
          </div>
        </div>

        <div class="mb-2">
          <input
            type="text"
            class="form-control form-control-lg rounded-pill"
            id="Business Name"
            aria-describedby="Business NameHelp"
            :placeholder="$store.state.brandSignupPage.Form1.bussinessName"
            v-model="FormData.merchant_name"
            :class="{ 'is-invalid': FromErrorFlags.merchant_name }"
          />
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.merchant_name[0] }}
          </div> -->
        </div>
        <div class="mb-2">
          <input
            type="text"
            class="form-control form-control-lg rounded-pill"
            id="BusinessInstagramHelp"
            aria-describedby="BusinessInstagramHelp"
            :placeholder="$store.state.brandSignupPage.Form1.businessInstagram"
            v-model="FormData.instagram_url"
            :class="{ 'is-invalid': FromErrorFlags.instagram_url }"
          />
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.instagram_url[0] }}
          </div> -->
        </div>

        <div class="ts-form-control-group d-flex mb-2">
          <select
            class="form-select form-select-lg rounded-pill ts-phone country-codes"
            aria-label="Default select example"
            v-model="FormData.country_code"
          >
            <option
              v-for="country in $store.state.countries"
              :value="country.code"
              :key="'mob-country-code-' + country.id"
              :data-text="country.country_name + '(' + country.code + ')'"
            >
              {{ country.country_name + "(" + country.code + ")" }}
            </option>
          </select>
          <div class="flex-fill">
            <input
              type="tel"
              class="form-control form-control-lg rounded-pill"
              id="tsPhone"
              aria-describedby="PhoneHelp"
              placeholder="Phone"
              v-model="FormData.number"
              :class="{ 'is-invalid': FromErrorFlags.number }"
            />
            <!-- <div class="invalid-feedback">
              {{ FromErrorMessages.number[0] }}
            </div> -->
          </div>
        </div>

        <div class="mb-0">
          <select
            class="form-select form-select-lg rounded-pill ts-phone"
            aria-label="Default select example"
            v-model="FormData.state_id"
            :class="{ 'is-invalid': FromErrorFlags.state_id }"
          >
            <option selected value="" disabled>Select location</option>
            <option
              v-for="location in $store.state.locations"
              :value="location.id"
              :key="'locations-' + location.id"
            >
              {{ location.state_name }}
            </option>
          </select>
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.state_id[0] }}
          </div> -->
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { axiosAPI } from "../../../axiosClient";
import { toast } from "vue3-toastify";
export default {
  name: "BrandsForm1",
  data() {
    return {
      FormData: {
        first_name: "",
        last_name: "",
        country_code: "+971",
        number: "",
        merchant_name: "",
        instagram_url: "",
        state_id: "",
      },
      FromErrorFlags: {
        first_name: false,
        last_name: false,
        number: false,
        merchant_name: false,
        instagram_url: false,
        state_id: false,
      },
      FromErrorMessages: {
        first_name: [],
        last_name: [],
        number: [],
        merchant_name: [],
        instagram_url: [],
        state_id: [],
      },
    };
  },
  watch: {
    "FormData.first_name"() {
      this.FromErrorFlags.first_name = false;
      this.FromErrorMessages.first_name = [];
    },
    "FormData.last_name"() {
      this.FromErrorFlags.last_name = false;
      this.FromErrorMessages.last_name = [];
    },
    "FormData.number"() {
      this.FromErrorFlags.number = false;
      this.FromErrorMessages.number = [];
    },
    "FormData.merchant_name"() {
      this.FromErrorFlags.merchant_name = false;
      this.FromErrorMessages.merchant_name = [];
    },
    "FormData.instagram_url"() {
      this.FromErrorFlags.instagram_url = false;
      this.FromErrorMessages.instagram_url = [];
    },
    "FormData.state_id"() {
      this.FromErrorFlags.state_id = false;
      this.FromErrorMessages.state_id = [];
    },
  },
  methods: {
    submitFrom1() {
      this.FromErrorMessages = {
        first_name: [],
        last_name: [],
        number: [],
        merchant_name: [],
        instagram_url: [],
        state_id: [],
      };
      let errorFlag = false;

      if (this.$store.state.brandSignupPage) {
        if (this.FormData.first_name.trim() == "") {
          this.FromErrorFlags.first_name = true;
          this.FromErrorMessages.first_name.pop(
            this.$store.state.brandSignupPage.errorMessages.first_name_empty
          );
          this.FromErrorMessages.first_name.push(
            this.$store.state.brandSignupPage.errorMessages.first_name_empty
          );
          errorFlag = true;
        } else {
          this.FromErrorFlags.first_name = false;
          this.FromErrorMessages.first_name.pop(
            this.$store.state.brandSignupPage.errorMessages.first_name_empty
          );
        }
        if (this.FormData.last_name.trim() == "") {
          this.FromErrorFlags.last_name = true;
          this.FromErrorMessages.last_name.push(
            this.$store.state.brandSignupPage.errorMessages.last_name_empty
          );
          errorFlag = true;
        } else {
          this.FromErrorFlags.last_name = false;
          this.FromErrorMessages.last_name.pop(
            this.$store.state.brandSignupPage.errorMessages.last_name_empty
          );
        }
        if (this.FormData.number.trim() == "") {
          this.FromErrorFlags.number = true;
          this.FromErrorMessages.number.push(
            this.$store.state.brandSignupPage.errorMessages.mobile_number_empty
          );
          errorFlag = true;
        } else {
          this.FromErrorFlags.number = false;
          this.FromErrorMessages.number.pop(
            this.$store.state.brandSignupPage.errorMessages.mobile_number_empty
          );
        }
        if (!/^\d+$/.test(this.FormData.number)) {
          this.FromErrorFlags.number = true;
          this.FromErrorMessages.number.push(
            this.$store.state.brandSignupPage.errorMessages
              .mobile_number_notnumber
          );
          errorFlag = true;
        } else {
          this.FromErrorFlags.number = false;
          this.FromErrorMessages.number.pop(
            this.$store.state.brandSignupPage.errorMessages
              .mobile_number_notnumber
          );
        }
        if (this.FormData.merchant_name.trim() == "") {
          this.FromErrorFlags.merchant_name = true;
          this.FromErrorMessages.merchant_name.push(
            this.$store.state.brandSignupPage.errorMessages.merchant_name_empty
          );
          errorFlag = true;
        } else {
          this.FromErrorFlags.merchant_name = false;
          this.FromErrorMessages.merchant_name.pop(
            this.$store.state.brandSignupPage.errorMessages.merchant_name_empty
          );
        }
        if (this.FormData.instagram_url == "") {
          this.FromErrorFlags.instagram_url = true;
          this.FromErrorMessages.instagram_url.push(
            this.$store.state.brandSignupPage.errorMessages.instagram_url_empty
          );
          errorFlag = true;
        } else {
          this.FromErrorFlags.instagram_url = false;
          this.FromErrorMessages.instagram_url.pop(
            this.$store.state.brandSignupPage.errorMessages.instagram_url_empty
          );
        }
        if (this.FormData.state_id == "") {
          this.FromErrorFlags.state_id = true;
          this.FromErrorMessages.state_id.push(
            this.$store.state.brandSignupPage.errorMessages.state_id_empty
          );
          errorFlag = true;
        } else {
          this.FromErrorFlags.state_id = false;
          this.FromErrorMessages.state_id.pop(
            this.$store.state.brandSignupPage.errorMessages.state_id_empty
          );
        }
      }
      if (errorFlag) {
        this.$emit("submitForm", {
          status: false,
        });
        return false;
      }
      this.FormData["step"] = 1;
      if(localStorage.getItem('utm_source')) {
        this.FormData["source"] = localStorage.getItem('utm_source');
      } else {
        this.FormData["source"] = '';
      }
      if(localStorage.getItem('utm_medium')) {
        this.FormData["utm_medium"] = localStorage.getItem('utm_medium');
      } else {
        this.FormData["utm_medium"] = '';
      }
      if(localStorage.getItem('utm_campaign')) {
        this.FormData["utm_campaign"] = localStorage.getItem('utm_campaign');
      } else {
        this.FormData["utm_campaign"] = '';
      }
      let _this = this;
      axiosAPI
        .post(`v1/brands/web/signup`, this.FormData)
        .then((response) => {
          if (response.data && response.data.status) {
            this.$emit("submitForm", {
              status: true,
              brand_id: response.data.data.brand_id,
            });
          } else {
            this.$emit("submitForm", {
              status: false,
            });
          }
        })
        .catch(function (error) {
          _this.$emit("submitForm", {
            status: false,
          });
          if (error.response && error.response.data) {
            let error_msgs = [];
            for (const error_msg in error.response.data) {
              if(error.response.data[error_msg].length > 0) {
                error_msgs.push(...error.response.data[error_msg]);
                // _this.FromErrorMessages[error_msg] = error.response.data[error_msg];
                _this.FromErrorFlags[error_msg] = true;
              }
            }
            if (_this.$store.state.errorMessges) {
              toast.info(_this.$store.state.errorMessges[error_msgs[0]]);
            }
          }
        });
    },
  },
  mounted() {
    let app = this;
    [...document.querySelectorAll(".country-codes")].forEach(function (item) {
      if (item.selectedIndex > 0) {
        item.options[item.selectedIndex].text = item.value;
      }
      app.$nextTick(function () {
        setTimeout(() => {
          if (item.selectedIndex > 0) {
            item.options[item.selectedIndex].text = item.value;
          }
        }, 600);
      });
      setTimeout(() => {
        if (item.selectedIndex > 0) {
          item.options[item.selectedIndex].text = item.value;
        }
      }, 1000);
      item.addEventListener("change", function handleChange(event) {
        event.target.options[event.target.selectedIndex].text =
          event.target.value;
      });
      item.addEventListener("mousedown", function handleChange(event) {
        [...event.target.options].forEach((option) => {
          option.text = option.dataset.text;
        });
        event.target.options[event.target.selectedIndex].text =
          event.target.value;
      });
    });
  },
};
</script>
<style lang="scss" scoped>
.country-codes {
  width: 25%;
}
</style>
