<template>
  <section class="ts-fuel-your-bussiness" v-if="$store.state.homePage">
    <div class="row align-items-center">
      <div class="col-lg-6 order-1 order-lg-0">
        <div class="position-relative z-minus-1 isolate mb-05">
          <img
            class="ts-fuel-your-bussiness__img w-100"
            :src="
              $store.state.baseUrl +
              $store.state.homePage.AsABrand.Image.data.attributes.url
            "
            :alt="
              $store.state.homePage.AsABrand.Image.data.attributes
                .alternativeText
            "
          />

          <div class="ts-bg-radius ts-bg-radius--primary"></div>
        </div>
        <div
          class="d-flex flex-column flex-sm-row justify-content-center align-items-center justify-content-lg-start flex-wrap gap-2 d-lg-none"
        >
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn--sm-lg ts-btn-secondary rounded-pill"
            :to="$store.state.homePage.AsABrand.GetDemoButton.to"
          >
            {{ $store.state.homePage.AsABrand.GetDemoButton.text }}
          </router-link>
          <router-link
            class="ts-btn text-center ts-btn--sm-lg ts-btn--sm-lg ts-btn-outline-primary rounded-pill"
            :to="$store.state.homePage.AsABrand.LearnMoreButton.to"
          >
            {{ $store.state.homePage.AsABrand.LearnMoreButton.text }}
          </router-link>
        </div>
      </div>
      <div class="col-lg-6 order-0 order-lg-1">
        <h1
          class="ts-fs-1 text-center text-lg-start ts-text-gray-5 fw-bold mb-0"
        >
          {{ $store.state.homePage.AsABrand.Title.title }}
          <span class="ts-text-primary-1">
            {{ $store.state.homePage.AsABrand.Title.titleHighlighted }}
          </span>
        </h1>
        <h1
          class="ts-fs-1 text-center text-lg-start ts-text-gray-5 fw-normal mb-04 mb-lg-06"
        >
          {{ $store.state.homePage.AsABrand.Title.subtitle }}
        </h1>
        <p class="ts-fs-5 text-center text-lg-start fw-light mb-09 mb-lg-07">
          {{ $store.state.homePage.AsABrand.description }}
        </p>
        <div
          class="d-none d-lg-flex flex-column flex-sm-row justify-content-center justify-content-lg-start flex-wrap gap-2"
        >
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-secondary rounded-pill"
            :to="$store.state.homePage.AsABrand.GetDemoButton.to"
            >{{
              $store.state.homePage.AsABrand.GetDemoButton.text
            }}</router-link
          >
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-outline-primary rounded-pill"
            :to="$store.state.homePage.AsABrand.LearnMoreButton.to"
          >
            {{
              $store.state.homePage.AsABrand.LearnMoreButton.text
            }}</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FuelYourBussiness",
};
</script>

<style lang="scss" scoped>
.ts-bg-radius {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px !important;
  height: 400px !important;
  padding-top: 400px !important;

  @media (min-width: 575px) {
    width: 100% !important;
    height: 100% !important;
    padding-top: 100% !important;
  }
  @media (min-width: 992px) {
    opacity: 63%;
    width: 773px !important;
    height: 773px !important;
    padding-top: 773px !important;
  }
}
.ts-fuel-your-bussiness__img {
  transform: scale();
}
</style>
