<template>
  <section class="ts-hero d-flex justify-content-center align-items-center">
    <img
      v-if="$store.state.homePage"
      class="ts-hero__bg-img"
      loading="lazy"
      :srcset="`${$store.state.baseUrl}${$store.state.homePage.Banner.backgroundImage.data.attributes.url} 500w, ${$store.state.baseUrl}${$store.state.homePage.Banner.backgroundImage.data.attributes.url}`"
      sizes="(max-width: 500px) 500px"
      :src="
        $store.state.baseUrl +
        $store.state.homePage.Banner.backgroundImage.data.attributes.url
      "
      :alt="
        $store.state.homePage.Banner.backgroundImage.data.attributes
          .alternativeText
      "
    />
    <div
      class="ts-container-fluid container-fluid"
      v-if="$store.state.homePage"
    >
      <div class="ts-hero__content mx-auto">
        <div class="ts-image ts-image__1">
          <img src="/images/Home/smile-face-hearts.png" alt="alternativeText" />
        </div>
        <div class="ts-image ts-image__2">
          <div class="ts-rotate">
            <img src="/images/Home/heart.png" alt="alternativeText" />
          </div>
        </div>
        <div class="ts-image ts-image__3">
          <img src="/images/Home/camera.png" alt="alternativeText" />
        </div>
        <div class="ts-image ts-image__4">
          <img src="/images/Home/donut.png" alt="alternativeText" />
        </div>
        <div class="ts-image ts-image__5">
          <div class="ts-rotate">
            <img src="/images/Home/heart.png" alt="alternativeText" />
          </div>
        </div>
        <div class="ts-image ts-image__6">
          <img src="/images/Home/burgur.png" alt="alternativeText" />
        </div>

        <div class="">
          <h1
            class="ts-hero__title text-center ts-fs-1 ts-text-gray-5 fw-bold mx-auto mb-12 mb-lg-09"
          >
            {{ $store.state.homePage.Banner.Title }}
          </h1>
          <div
            class="ts-hero__counter d-flex gap-4 justify-content-between mb-06 mb-lg-09 mx-auto"
          >
            <div>
              <p class="text-start ts-fs-5 ts-text-gray-4 mb-0">
                {{ $store.state.homePage.Banner.Creator.title }}
              </p>
              <h1 class="ts-text-primary-1 ts-fs-1bg fw-bold mb-0">
                {{ $store.state.homePage.Banner.Creator.details }}
              </h1>
            </div>
            <div>
              <p class="text-start ts-fs-5 ts-text-gray-4 mb-0 ms-2">
                {{ $store.state.homePage.Banner.Brands.title }}
              </p>
              <h1 class="ts-text-primary-1 ts-fs-1bg fw-bold mb-0">
                {{ $store.state.homePage.Banner.Brands.details }}
              </h1>
            </div>
            <div>
              <p class="ts-fs-5 ts-text-gray-4 mb-0">
                {{ $store.state.homePage.Banner.Campaigns.title }}
              </p>
              <h1 class="ts-text-primary-1 ts-fs-1bg fw-bold mb-0 ms-2">
                {{ $store.state.homePage.Banner.Campaigns.details }}
              </h1>
            </div>
          </div>

          <div
            class="d-flex flex-column flex-sm-row align-items-center gap-3 justify-content-center"
          >
            <router-link
              class="ts-btn ts-btn--sm-lg ts-btn-primary rounded-pill px-5"
              :to="$store.state.homePage.Banner.brandButton.to"
            >
              {{ $store.state.homePage.Banner.brandButton.text }}
            </router-link>
            <router-link
              class="ts-btn ts-btn--sm-lg ts-btn-outline-primary rounded-pill px-5"
              :to="$store.state.homePage.Banner.creatorButton.to"
            >
              {{ $store.state.homePage.Banner.creatorButton.text }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TsHero",
};
</script>

<style scoped lang="scss">
.ts-hero {
  position: relative;
  isolation: isolate;
  &__bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__content {
    max-width: 570px;
  }

  &__content {
    position: relative;
  }
  &__counter {
    max-width: 520px;

    @media (max-width: 991px) {
      max-width: 480px;
    }
    @media (max-width: 767px) {
      max-width: 420px;
    }
    @media (max-width: 600px) {
      max-width: 350px;
    }
    @media (max-width: 500px) {
      max-width: 320px;
    }
  }
}

.ts-image {
  position: absolute;

  img {
    width: clamp(80px, 11vw, 140px) !important;
  }

  &__1 {
    img {
      animation: floating 2.5s ease-in-out infinite;
    }
  }

  &__2 {
    img {
      animation: floating 3s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(30deg);
    }
  }

  &__3 {
    img {
      animation: floating 3.5s ease-in-out infinite;
    }
  }

  &__4 {
    img {
      animation: floating 4s ease-in-out infinite;
    }
  }

  &__5 {
    img {
      animation: floating 4s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(-30deg);
    }
  }

  &__6 {
    img {
      animation: floating 4.5s ease-in-out infinite;
    }
  }

  &__1,
  &__3,
  &__5 {
    left: 0;
    transform: translateX(-100%);
  }

  &__2,
  &__4,
  &__6 {
    right: -29%;
    transform: translateX(100%);
  }

  &__1 {
    left: -29%;
  }

  &__1,
  &__2 {
    transform: translateY(-100%);
  }

  &__3 {
    top: 38%;
    transform: translate(-100%, -100%);
    left: -60px;
  }

  &__4 {
    top: 50%;
    right: -28%;
    transform: translate(100%, -100%);
  }

  &__5 {
    top: 109%;
    left: -10%;
    transform: translate(-100%, -100%);
  }

  &__6 {
    top: 108%;
    right: -5%;
    transform: translate(100%, -100%);
  }

  @media (max-width: 570px) {
    &__1 {
      top: 106%;
      left: auto;
      right: 0;
      transform: translate(17%, 48%);
    }

    &__2 {
      display: none;
    }

    &__3 {
      top: 102%;
      left: 0;
      right: auto;
      transform: translate(17%, 48%);
    }

    &__4 {
      transform: translate(54%, -100%);
    }
    &__5 {
      top: 95%;
      left: 9%;
      transform: translate(-100%, -100%);
    }
    &__6 {
      top: 98%;
      right: 13%;
      transform: translate(100%, -100%);
    }
  }
}

@keyframes floating {
  0% {
    transform: rotate(-5deg) translatey(0px);
  }

  50% {
    transform: rotate(-3deg) translatey(7px);
  }

  100% {
    transform: rotate(-5deg) translatey(0px);
  }
}
</style>
