<template>
  <LoadingScreen v-if="loading" />
  <div>
    <TsHero />
    <div class="ts-container-fluid container-fluid">
      <BrandsLogos />
      <FuelYourBussiness />
    </div>
    <BrandLoves v-bind:haveGradient="true">
      <h1
        v-if="$store.state.homePage"
        class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13"
      >
        {{ $store.state.homePage.BrandSectionTitle }}
      </h1>
    </BrandLoves>
    <div class="ts-container-fluid container-fluid">
      <UnlockBenifits />
    </div>
    <div class="ts-container-fluid container-fluid">
      <CreatorsReviews>
        <h1
          v-if="$store.state.homePage"
          class="ts-fs-1 text-center ts-text-gray-5 fw-bold mb-07 mb-lg-13"
        >
          {{ $store.state.homePage.CreatorSectionTitle }}
        </h1>
      </CreatorsReviews>
      <LastestNews>
        <h1
          class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13"
          v-if="$store.state.homePage"
        >
          {{ $store.state.homePage.BlogSectionTitle }}
        </h1>
      </LastestNews>
    </div>
  </div>
</template>

<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import TsHero from "../components/Home/TsHero.vue";
import BrandsLogos from "../components/Common/BrandsLogos.vue";
import FuelYourBussiness from "../components/FuelYourBussiness.vue";
import UnlockBenifits from "../components/UnlockBenifits.vue";
import BrandLoves from "../components/Common/BrandLoves.vue";
import CreatorsReviews from "../components/Common/CreatorsReviews.vue";
import LastestNews from "../components/Common/LastestNews.vue";

export default {
  name: "App",
  components: {
    LoadingScreen,
    TsHero,
    BrandsLogos,
    FuelYourBussiness,
    UnlockBenifits,
    BrandLoves,
    CreatorsReviews,
    LastestNews,
  },

  data() {
    return {
      loading: true, // Initialize loading flag
    };
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if Active language Change but not the same as the old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadData();
      }
    },
  },

  beforeMount() {
    this.loadData();
  },

  methods: {
    loadData() {
      // let isDataLoaded = true;
      // if (this.$store.state.homePage !== null) {
      //   isDataLoaded = false;
      // }
      if (this.loading) {
        this.$store.dispatch("getHomePage").then(() => {
          // After data is loaded, set loading flag to false
          setTimeout(() => {
            this.loading = false;
          }, 700);
          // this.loading = false;
          // if (isDataLoaded) {
          // } else {
          //   setTimeout(() => {
          //     this.loading = false;
          //   }, 2000);
          // }
        });
      }
    },
  },
};
</script>
