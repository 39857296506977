<template>
  <section class="ts-how-it-works" v-if="$store.state.creatorPage">
    <h1 class="ts-fs-1 text-center ts-text-gray-5 fw-bold mb-08 mb-lg-13">
      {{ $store.state.creatorPage.HowItWorks.title }}
    </h1>
    <div class="ts-how-it-works__content">
      <div class="ts-bg-radius ts-bg-radius--secondary z-minus-1"></div>
      <div
        class="ts-bg-radius ts-bg-radius--primary-secondary--mix z-minus-1"
      ></div>
      <div class="row align-items-center">
        <div class="col-lg-4 d-none d-xl-block text-center">
          <img
            class="ts-phone-img w-100"
            :src="
              $store.state.baseUrl +
              $store.state.creatorPage.HowItWorks.image.data.attributes.url
            "
            :alt="
              $store.state.creatorPage.HowItWorks.image.data.attributes
                .alternativeText
            "
          />
        </div>
        <div class="col-xl-8">
          <div class="row row-cols-md-2">
            <div class="mb-4">
              <div class="ts-creator-card ts-shadow-1 ts-text-gray-7 h-100">
                <img
                  class="ts-creator-card__img mb-02 mb-lg-07"
                  :src="$store.state.baseUrl + '/uploads/burger_f08e9ed903.svg'"
                  alt="alternativeText"
                />
                <h3 class="ts-fs-4 fw-bold mb-02">
                  <span class="ts-text-primary-1">{{
                    $store.state.creatorPage.HowItWorks.Cards[0].titleNo
                  }}</span>
                  {{ $store.state.creatorPage.HowItWorks.Cards[0].titleText }}
                </h3>
                <h5 class="ts-fs-5 fw-light mb-0">
                  {{ $store.state.creatorPage.HowItWorks.Cards[0].description }}
                </h5>
              </div>
            </div>
            <div class="mb-4">
              <div class="ts-creator-card ts-shadow-1 ts-text-gray-7 h-100">
                <img
                  class="ts-creator-card__img mb-02 mb-lg-07"
                  :src="$store.state.baseUrl + '/uploads/redeam_aab2802736.svg'"
                  alt="alternativeText"
                />
                <h3 class="ts-fs-4 fw-bold mb-02">
                  <span class="ts-text-primary-1">{{
                    $store.state.creatorPage.HowItWorks.Cards[1].titleNo
                  }}</span
                  >{{ $store.state.creatorPage.HowItWorks.Cards[1].titleText }}
                </h3>
                <h5 class="ts-fs-5 fw-light mb-0">
                  {{ $store.state.creatorPage.HowItWorks.Cards[1].description }}
                </h5>
              </div>
            </div>
            <div class="mb-4 mb-md-0">
              <div class="ts-creator-card ts-shadow-1 ts-text-gray-7 h-100">
                <img
                  class="ts-creator-card__img mb-02 mb-lg-07"
                  :src="$store.state.baseUrl + '/uploads/camera_1248f8ba80.svg'"
                  alt="alternativeText"
                />
                <h3 class="ts-fs-4 fw-bold mb-02">
                  <span class="ts-text-primary-1">{{
                    $store.state.creatorPage.HowItWorks.Cards[2].titleNo
                  }}</span
                  >{{ $store.state.creatorPage.HowItWorks.Cards[2].titleText }}
                </h3>
                <h5 class="ts-fs-5 fw-light mb-0">
                  {{ $store.state.creatorPage.HowItWorks.Cards[2].description }}
                </h5>
              </div>
            </div>
            <div class="mb-4 mb-md-0">
              <div class="ts-creator-card ts-shadow-1 ts-text-gray-7 h-100">
                <img
                  class="ts-creator-card__img mb-02 mb-lg-07"
                  :src="$store.state.baseUrl + '/uploads/heart_1421f6d262.svg'"
                  alt="alternativeText"
                />
                <h3 class="ts-fs-4 fw-bold mb-02">
                  <span class="ts-text-primary-1">{{
                    $store.state.creatorPage.HowItWorks.Cards[3].titleNo
                  }}</span
                  >{{ $store.state.creatorPage.HowItWorks.Cards[3].titleText }}
                </h3>
                <h5 class="ts-fs-5 fw-light mb-0">
                  {{ $store.state.creatorPage.HowItWorks.Cards[3].description }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HowItWorks",
};
</script>

<style scoped lang="scss">
.ts-phone-img {
  max-width: 368px;
}

.ts-creator-card__img {
  width: clamp(1.9375rem, 5vw, 3.125rem);
}

.ts-how-it-works__content {
  position: relative;
}

.ts-bg-radius--secondary {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-30%, -40%);
  width: 80% !important;
  height: 80% !important;
  padding-top: 80% !important;
  z-index: -2;
}

.ts-bg-radius--primary-secondary--mix {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(1%, -26%);
  width: 805px !important;
  height: 805px !important;
  padding-top: 805px !important;
  z-index: -1;
  opacity: 90%;
}

@media (max-width: 991px) {
  .ts-bg-radius--secondary {
    position: absolute;
    left: 2%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 120% !important;
    height: 100% !important;
    padding-top: auto !important;
    z-index: -2;
  }

  .ts-bg-radius--primary-secondary--mix {
    transform: translate(1%, -42%);
  }
}
</style>
