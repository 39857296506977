<template>
  <section class="ts-latest-news">
    <slot></slot>
    <swiper
      :slidesPerView="1"
      :spaceBetween="16"
      :pagination="{
        clickable: true,
      }"
      :modules="modules"
      :breakpoints="{
        '700': {
          slidesPerView: 2,
          spaceBetween: 28,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 36,
        },
      }"
      class="ts-custom-swiper mySwiper"
      v-if="$store.state.blogs"
    >
      <swiper-slide
        class="mb-4"
        v-for="card in $store.state.blogs"
        :key="card.id"
      >
        <NewsCard
          :title="card.attributes.Title"
          :imageUrl="card.attributes.Image.data.attributes.url"
          :date="card.attributes.createdAt.split('T')[0]"
          :generatedBy="card.attributes.Information.author"
          :id="card.id"
        />
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import NewsCard from "../Cards/NewsCard.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

export default {
  name: "RelatedNews",

  components: {
    Swiper,
    SwiperSlide,
    NewsCard,
  },

  setup() {
    return {
      modules: [Pagination],
    };
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.callBlogs(this.$store.state.activeBlogId);
      }
    },
  },
  created() {
    this.callBlogs(1);
  },

  methods: {
    callBlogs(topicId) {
      this.$store.dispatch("getBlogs", {
        topicId,
        page: this.$store.state.blogPagination.page,
      });
    },
  },
};
</script>
